
import classNames from 'classnames'

import { t }  from 'tools'
import config from 'config'

const TabeeAd = ({ data, company, id, isLite, isMine }) => {
    const
        isNFC = ( isMine || ( isLite && company )),
        cmp = data?.company_owner || company,
        button = (
            <a href="https://evizy.ru" target="_blank" className="evizy-ad-button" data-long={t( 'publicCard.AD_GET_LONG' )} data-short={t( 'publicCard.AD_GET_SHORT' )} rel="noreferrer">
                {t( 'publicCard.AD_GET' )}
            </a>
        )

    return (
        <aside className={classNames( 'public-card-evizy', isNFC && 'public-card-evizy-activate' )}>
            {
                isNFC && (
                    cmp && cmp.square_logo && !cmp.is_public
                        ? (
                            <div className="evizy-ad-logo-container">
                                <img src={`${config.api.url}/media/${cmp.square_logo}`} alt={cmp.name} />
                                { button }
                            </div>
                        )
                        : (
                            <div className="evizy-ad-logo-container">
                                <img src='/firm.svg' alt="" />
                                { button }
                            </div>
                        )
                )
            }
            <div className="pct-content">
                <p>{
                    isNFC
                        ? t( 'publicCard.AD_NFC_TEXT' )
                        : (
                            !isMine
                                ? t( 'publicCard.AD_DOWNLOAD_TEXT' )
                                : (
                                    <span>
                                        {t( 'publicCard.AD_MINE_TEXT_BEFORE' )}
                                        <b>{t( 'publicCard.AD_MINE_TEXT_BOLD' )}</b>
                                        {t( 'publicCard.AD_MINE_TEXT_AFTER' )}
                                    </span>
                                )
                        )
                }</p>
                {
                    !isNFC
                        ? (
                            <ul className="pct-buttons">
                                <li>
                                    <a href={`https://mua4.adj.st/premium/branded?adj_t=iwo0eue&adj_deep_link=tabee%3A%2F%2Fpremium%2Fbranded&adj_adgroup=${data?.owner_user?.id}&adj_creative=${id}&adj_label=link_strong_branded_${id}&adj_fallback=https%3A%2F%2Ftabee.app&adj_redirect_ios=https%3A%2F%2Fapps.apple.com%2Fsearch%3Fterm%3DTabee%2520Business%2520Card%2520Reader%2520%2526%2520Creator%2520by%2520Tabee%26entity%3Dsoftware%26country%3DUS%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1`}>
                                        <img src={'https://api.evizy.ru/static/tabee/images/mails/store-appstore.png'} alt="Get on the AppStore" />
                                    </a>
                                </li>
                                <li>
                                    <a href={`https://mua4.adj.st/premium/branded?adj_t=iwo0eue&adj_deep_link=tabee%3A%2F%2Fpremium%2Fbranded&adj_adgroup=${data?.owner_user?.id}&adj_creative=${id}&adj_label=link_strong_branded__${id}&adj_fallback=https%3A%2F%2Ftabee.app&adj_redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android%26hl%3Den%26gl%3DUS`}>
                                        <img src={'https://api.evizy.ru/static/tabee/images/mails/store-googleplay.png'} alt="Get on the Google Play" />
                                    </a>
                                </li>
                            </ul>
                        )
                        : button
                }
                <img src={`https://view.adjust.com/impression/iwo0eue?adgroup=${data?.owner_user?.id}&creative=${id}&label=link_strong_branded__${id}`} width="1" height="1" css="height:1px !important; width:1px !important; border-width:0 !important; margin-top:0 !important; margin-bottom:0 !important; margin-right:0 !important; margin-left:0 !important; padding-top:0 !important;padding-bottom:0 !important; padding-right:0 !important; padding-left:0 !important;" alt="" />
            </div>
        </aside>
    )
}

export default TabeeAd
