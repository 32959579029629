import React             from 'react'
import { Button, Input } from 'antd'
import classNames        from 'classnames'

import { generate, request, ui } from 'tools'

import connector from './connector'

class Login extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {
            username: '',
            password: '',
            email:    props.email || '',
            locked:   false,
            message:  '',
            restore:  false
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { logged, lastTry, msg, info } = this.props,
            { remember, restore } = this.state

        ;( remember !== prevState.remember ) && ( request.storage.set( 'remember', remember.toString()))
        ;( msg !== prevProps.msg ) && ( this.set.state({ message: msg, password: '' }))

        if ( lastTry !== prevProps.lastTry || logged !== prevProps.logged ) {
            this.set.locked( false )
            if ( logged ) {
                this.props.flushSearchCard()
                this.props.setAccount( info.id )
            } else {
                ui.focusFirstInput()
            }
        }

        ( restore !== prevState.restore ) && ( this.props.setMessage( '' ))
    }

    update = ( key, handler ) => e => {
        this.set.message( '' )
        this.set[ handler ]( key )( e )
    }

    login = () => {
        const
            { locked, username, password } = this.state

        if ( locked || !username || !password ) { return }

        this.set.state({
            locked:  true,
            message: ''
        })

        this.props.auth({ username, password })
    }

    next = () => document.querySelector( 'input[type="password"]' ).focus()

    sendRequest = () => {
        const
            { email, locked } = this.state

        if ( locked || !email ) { return }

        this.set.locked( true )
        this.props.resetPass({ email })
    }

    setEmail = e => {
        this.props.setMessage( '' )
        this.set.email( e.target.value.replace( / /g, '' ))
    }

    restoreForm = () => {
        const
            { resetSent } = this.props,
            { locked, email, message } = this.state

        return (
            <>
                <p>Enter your email in the field below.</p>

                <form className="pcf-form">
                    <div className="pcf-form-item">
                        <Input
                            autoFocus
                            placeholder = "Your Email"
                            disabled = { locked }
                            value = { email }
                            className = 'force-lowercase'

                            onChange = { this.setEmail }
                            onPressEnter = { this.sendRequest }
                        />
                    </div>

                    {
                        !!message && (
                            <div className="login-message">
                                { message }
                            </div>
                        )
                    }

                    <div className="login-links">
                        <span className="list-item login-link" onClick={() => this.set.restore( false )}>
                            <span>Return to Log In</span>
                        </span>
                    </div>

                    {
                        !resetSent && (
                            <Button
                                className = "fit-container"
                                type = "primary"
                                disabled = { locked || !email || resetSent }
                                style = { this.props.buttonStyle }

                                onClick = { this.sendRequest }
                            >
                                {
                                    locked
                                        ? 'Sending...'
                                        : 'Send reset link'
                                }
                            </Button>
                        )
                    }
                </form>
            </>
        )
    }

    loginForm = () => {
        const
            { locked, username, password, message } = this.state

        return (
            <>
                <p>You&apos;re already have a Evizy Account.<br/>Please sign in.</p>

                <form className="pcf-form">
                    <div className="pcf-form-item">
                        <Input
                            autoFocus
                            placeholder = "Username"
                            disabled = { locked }
                            value = { username }
                            className = 'force-lowercase'

                            onChange = { this.update( 'username', 'byval' ) }
                            onPressEnter = { this.next }
                        />
                    </div>

                    <div className="pcf-form-item">
                        <Input.Password
                            type = "password"
                            placeholder = "Password"
                            disabled = { locked }
                            value = { password }

                            onChange = { this.update( 'password', 'byval' ) }
                            onPressEnter = { this.login }
                        />
                    </div>

                    {
                        !!message && (
                            <div className="login-message">
                                { message }
                            </div>
                        )
                    }

                    <div className="login-links">
                        <span className="list-item login-link" onClick={() => this.set.restore( true )}>
                            <span>Forgot your Evizy password?</span>
                        </span>
                    </div>

                    <Button
                        className = "fit-container"
                        type = "primary"
                        disabled = { locked || !username || !password }
                        style = { this.props.buttonStyle }

                        onClick = { this.login }
                    >
                        {
                            locked
                                ? 'Singing in...'
                                : 'Sign in'
                        }
                    </Button>
                </form>
            </>
        )
    }

    render () {
        const
            { indie } = this.props,
            { restore } = this.state

        return (
            <div className={classNames( 'login-form', indie ? 'public-card-form' : 'inner-form' )} style={{ height: 320 }}>
                {
                    restore
                        ? this.restoreForm()
                        : this.loginForm()
                }
            </div>
        )
    }
}

export default connector( Login )
