import { useSelector, useDispatch } from 'react-redux'
import moment                       from 'moment'

import TabeeAuth from '@tabee/frontend-auth'

import { authActions } from 'services'
import { t }           from 'tools'

const PublicCardFooter = () => {
    const
        dispatch = useDispatch(),
        user = useSelector( state => state.user?.info ),
        signIn = e => {
            e.preventDefault()
            TabeeAuth.SSOSignIn( '/', window.location.pathname )
        },
        signOut = e => {
            e.preventDefault()
            dispatch( authActions.signOut())
        }

    return (
        <footer className="public-card-footer">
            <ul className="public-card-afterwords">
                <li>{t( 'publicCard.FOOTER_TABEE_TEAM' )}</li>
                <li>© { moment().format( 'YYYY' ) } Evizy</li>
                {
                    user
                        ? <li><a href='#' onClick={signOut}>{ t( 'admin.signOut' ) }</a> • <a href='#' onClick={signIn}>{ t( 'admin.switchCard' ) }</a></li>
                        : <li><a href='#' onClick={signIn}>{ t( 'auth.loginSignIn' ) }</a></li>
                }
                <li><a href="mailto:contact@evizy.ru">contact@evizy.ru</a></li>
            </ul>
        </footer>
    )
}

export default PublicCardFooter
