

import './public-card-header.scss'

const PublicCardHeader = () => {
    return (
        <header className="public-card-header">
            <picture>
                <source srcSet={'https://api.evizy.ru/static/tabee/images/mails/dark/tabee-logo.png'} media="(prefers-color-scheme: dark)" />
                <source srcSet={'https://api.evizy.ru/static/tabee/images/mails/tabee-logo.png'} media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
                <img src={'https://api.evizy.ru/static/tabee/images/mails/evizy-logo.png'} id="evizy-logo" loading="lazy" width="124" height="22" alt="Evizy Logo" className="public-card-logo" />
            </picture>
        </header>
    )
}

export default PublicCardHeader
