const
    scrolls = {
        renderTrackVertical:     props => <div {...props} className="v-scroll-track" />,
        renderThumbVertical:     props => <div {...props} className="v-scroll-thumb" />,
        renderTrackHorizontal:   props => <div {...props} className="h-scroll-track" />,
        renderThumbHorizontal:   props => <div {...props} className="h-scroll-thumb" />,
        hideTracksWhenNotNeeded: true
    },

    dates = {
        def:     'YYYY-MM-DDTHH:mm:ss',
        classic: 'DD.MM.YYYY',
        nice:    'D MMMM, YYYY',
        time:    'HH:mm',
        date:    'YYYY-MM-DD'
    },


    apiUrl = process.env.REACT_APP_BASE,

    config = {
        title:     'Evizy',
        indexPage: `${process.env.REACT_APP_SSO}/`,

        sso: { url: process.env.REACT_APP_SSO },

        api: {
            url:       apiUrl,
            noHttpUrl: apiUrl.replace( 'http:', '' ).replace( 'https:', '' ).replace( /^\/\//, '' ),
            project:   '5ff3739e-03be-11eb-801b-6a13bef2453b'
        },

        scrolls,

        forms: {
            layout: {
                labelCol:   { span: 8 },
                wrapperCol: { span: 16 },
            }
        },

        images: {
            media:          `${apiUrl}/media`,
            resizer:        '//img.evizy.ru',
            latestFallback: '//evizy.ru/files/placeholder_avatar@2x.jpg'
        },

        links: {
            login:   '/login',
            signup:  '/sign-up',
            android: 'https://app.adjust.com/rfdji5l?fallback_lp=https%3A%2F%2Ftabee.app&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fsearch%3Fterm%3DTabee%2520Business%2520Card%2520Creator%2520by%2520Tabee%26entity%3Dsoftware%26country%3DUS%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1',
            ios:     'https://app.adjust.com/rfdji5l?fallback_lp=https%3A%2F%2Ftabee.app&fallback=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fid1005851014%3Fpt%3D119303076%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fsearch%3Fterm%3DTabee%2520Business%2520Card%2520Creator%2520by%2520Tabee%26entity%3Dsoftware%26country%3DUS%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1',

        },

        publicEmailCompany: '19f2ebdc-e0a8-11e6-a630-6a13bef2453b',

        defaultDesign: {
            id:                        'default',
            company_bg_color:          '#283340',
            company_text_color:        '#ffffff',
            company_custom_text_color: '#5FC4FF',
        },

        ui: {
            prefix:        '_evizy',
            dates,
            stub:          count => ( new Array( count || 4 ).fill( 0 ).map(( z, i ) => ({ id: i }))),
            notifications: {
                getContainer: () => document.querySelector( '.page-content' ),
                duration:     1
            },
            breakpoints: { mobile: 540 },
        }
    }

export default Object.freeze( Object.assign({}, config ))
