
import classNames    from 'classnames'
import { InlineSvg } from 'components'

const PublicLoader = ({ visible }) => {
    return (
        <InlineSvg className={classNames( 'public-loader', 'center-container', visible && 'is-visible' )} url="/loader.svg" />
    )
}

export default PublicLoader
